import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSurveyState } from "../SurveyContext";
import { SurveyField } from "../SurveyField/SurveyField";
import { SubmitSurveyData } from "../../../services/SubmitSurveyData";
import { verifyOtp } from "../../../services/auth";
import SurveyOtpField from "../SurveyOtpField";
import "./SurveyStep.scss";
import { useAppContext } from "../../../context/AppContext";
import Loader from "../../Loader";
import _ from "lodash";

export const SurveyStep = (props) => {
  const { userSurveyInfo, setUserSurveyInfo, setModalState, user } =
    useAppContext();
  const {
    allQuestions,
    section,
    sectionIndex,
    nextSection,
    previousSection,
    surveySearchableTag,
    lastPathSegment,
    hasUserData,
    treatmentSurveyPath,
    surveyTitle,
  } = props;
  const currentlyOnLastSection = nextSection === undefined;
  const currentlyOnFirstSection = previousSection === undefined;
  const currentlyOnSecondSection = sectionIndex === 1;
  const getPaymentNonceRef = useRef();

  const initialValueQuestionVisibilitiesMap = () => {
    const visibilitiesMap = {};

    for (const question of allQuestions) {
      visibilitiesMap[question.searchable_tag] = question.is_default_visible;
    }
    return visibilitiesMap;
  };

  const [questionVisibilitiesMap, setQuestionVisibilitiesMap] = useState(
    initialValueQuestionVisibilitiesMap()
  );

  initialValueQuestionVisibilitiesMap();

  const updateQuestionVisibilities = (question, answer) => {
    const dependentQuestionConditions = question.dependent_question_conditions;

    if (!dependentQuestionConditions) {
      return;
    }

    let alterationObject = {};

    const updateVisibility = (questionTags, visibility) => {
      questionTags.forEach((questionTag) => {
        if (visibility || alterationObject[questionTag] !== true) {
          alterationObject[questionTag] = visibility;
        }
      });
    };

    for (const key in dependentQuestionConditions) {
      const questionTags =
        dependentQuestionConditions[key].question_searchable_tags;
      const visibility = answer.searchable_tag === key;

      updateVisibility(questionTags, visibility);

      // TODO: refactor to reduce complexity
      if (!visibility) {
        questionTags.forEach((questionTag) => {
          allQuestions.forEach((subQuestion) => {
            if (subQuestion.searchable_tag === questionTag) {
              const subDependentConditions =
                subQuestion.dependent_question_conditions;
              if (subDependentConditions) {
                for (const subKey in subDependentConditions) {
                  const subQuestionTags =
                    subDependentConditions[subKey].question_searchable_tags;
                  updateVisibility(subQuestionTags, false);
                }
              }
            }
          });
        });
      }
    }

    setQuestionVisibilitiesMap({
      ...questionVisibilitiesMap,
      ...alterationObject,
    });
  };

  const [state, setState] = useSurveyState();
  const {
    handleSubmit,
    register,
    trigger,
    watch,
    formState: { errors, isValid, isSubmitting },
    control,
    setError,
    clearErrors,
    setValue,
    unregister,
  } = useForm({ defaultValues: state, mode: "onSubmit" });

  const watchPassword = watch("password");
  const navigate = useNavigate();

  const saveData = async (data) => {
    let submissionData = data;
    try {
      if (currentlyOnLastSection) {
        const nonceData = await getPaymentNonceRef.current.getNonce();
        const completed_at = new Date().toISOString();
        submissionData = {
          ...data,
          ...nonceData,
          completed_at,
        };
      }

      setState({ ...state, ...submissionData });
    } catch (error) {
      setModalState({
        switch: true,
        message: error.messsage,
        status: "error",
      });
      console.log(error);
    }
    try {
      if (sectionIndex === 2 && !hasUserData) {
        await verifyOtp({ phone: data?.cell_question, code: data?.otp });
      }

      const userSurvey = await SubmitSurveyData(
        submissionData,
        surveySearchableTag
      );

      // Set user related data
      const userData = {};
      if (!userSurveyInfo?.id) {
        userData.id = userSurvey?.attributes?.user_id;
        userData.userSurveyId = userSurvey?.id;
      }

      if (!userSurveyInfo?.phone && submissionData?.cell_question)
        userData.phone = submissionData?.cell_question;

      if (!_.isEmpty(userData)) {
        setUserSurveyInfo({ ...userSurveyInfo, ...userData });
      }

      if (currentlyOnLastSection) navigate(`${treatmentSurveyPath}/password`);
      else navigate(`${treatmentSurveyPath}/${nextSection.name}`);
    } catch (error) {
      setModalState({
        switch: true,
        message: error.response?.data?.errors,
        status: "error",
      });
      if (error.message.includes("Code is Invalid")) {
        setError("otp", {
          type: "manual",
          message: "Invalid OTP",
        });
      }
      console.error("Error submitting survey:", error);
    }
  };

  const saveDataAndGoBack = (data) => {
    // setState({ ...state, ...data });
    trigger();
    if (currentlyOnFirstSection) {
      navigate(`${treatmentSurveyPath.split("/")[1]}`);
    } else if (currentlyOnSecondSection) {
      navigate(`${treatmentSurveyPath}`);
    } else {
      navigate(-1);
    }
  };

  const fieldsetRef = useRef(null);
  const pharmacyDivRef = useRef(null);

  const [pharmacyHeight, setPharmacyHeight] = useState(204);

  const pharmacyHeightHandler = (height) => {
    setPharmacyHeight(height);
  };

  // Reset on page refreshed
  useEffect(() => {
    if (sectionIndex !== 0 && Object.keys(userSurveyInfo).length === 0) {
      navigate(`${treatmentSurveyPath}`);
    }
  }, [lastPathSegment]);

  useEffect(() => {
    document.documentElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [section.name]);

  useEffect(() => {
    let extraHeight = 0;
    const currentWidth = window.innerWidth;

    if (currentWidth > 1536) {
      extraHeight = 150;
    } else if (currentWidth > 1024) {
      extraHeight = 170;
    } else if (currentWidth > 768) {
      extraHeight = 100;
    } else {
      extraHeight = 90;
    }

    if (section.name === "Pharmacy") {
      fieldsetRef.current.style.height = `${pharmacyHeight + extraHeight}px`;
    } else {
      fieldsetRef.current.style.height = `auto`;
    }
  }, [pharmacyHeight, section.name]);

  const groupElementsByType = (questions) => {
    const grouped = [];
    let currentGroup = [];
    let prevType = null;

    questions.forEach((question) => {
      const isVisible =
        questionVisibilitiesMap[question.searchable_tag] !== false;

      if (isVisible) {
        const type = question.input_type;

        if (type !== prevType && currentGroup.length > 0) {
          grouped.push(currentGroup);
          currentGroup = [];
        }

        currentGroup.push(question);
        prevType = type;
      }
    });

    if (currentGroup.length > 0) {
      grouped.push(currentGroup);
    }

    const result = {};
    grouped.forEach((group) => {
      const width = 100 / group.length;
      group.forEach((question) => {
        result[question.searchable_tag] = `${width}%`;
      });
    });

    return result;
  };

  const sortedQuestions = section.questions.sort((a, b) => a.order - b.order);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(saveData)();
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [handleSubmit, saveData]);

  // Effect to check the value of used_dauchi_question, and if it is yes, navigate to the login page
  const usedDauchiBefore = watch("used_dauchi_question");
  useEffect(() => {
    usedDauchiBefore === "yes_answer" && navigate("/login");
  }, [usedDauchiBefore]);

  return (
    <>
      <form id="parent-form">
        <fieldset className="" ref={fieldsetRef}>
          <legend className="text-primary text-[12px] lg:text-xl 2xl:text-[1.93rem] font-subHeadline mx-auto border-y border-lightGray py-8 w-full mb-8 onboarding-section-label">
            <p className="text-[1.3rem] lg:text-[2rem] leading-loose">
              {surveyTitle}
            </p>
            {section.subheader}
          </legend>
          <div
            id={section.name}
            ref={pharmacyDivRef}
            className={`w-full flex ${
              section.name === "Information"
                ? "flex-row flex-wrap bg-white px-[12px] md:px-[25px] lg:px-[50px] rounded-2xl py-[50px]"
                : "flex-col"
            }`}
          >
            {section.name === "Information" && (
              <div className="flex w-full font-medium text-base lg:text-[1.25rem]">
                <h2>Personal Information</h2>
              </div>
            )}
            {sortedQuestions.map((question, questionIndex) => {
              const isVisible =
                questionVisibilitiesMap[question.searchable_tag] !== false;
              return (
                isVisible && (
                  <>
                    <SurveyField
                      key={questionIndex}
                      question={question}
                      register={register}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      control={control}
                      setError={setError}
                      clearErrors={clearErrors}
                      watch={watch}
                      setValue={setValue}
                      updateQuestion
                      Send
                      OTP
                      updateQuestionVisibilities={updateQuestionVisibilities}
                      pharmacyHeightHandler={pharmacyHeightHandler}
                      sectionName={section.name}
                      fieldWidth={groupElementsByType(section.questions)}
                      getPaymentNonceRef={getPaymentNonceRef}
                      trigger={trigger}
                      unregister={unregister}
                      hasUserData={hasUserData}
                    />
                    <br />
                  </>
                )
              );
            })}
            {sectionIndex === 2 && !hasUserData && (
              <SurveyOtpField register={register} errors={errors} />
            )}
          </div>
        </fieldset>
      </form>
      <div
        className={`max-w-max ${
          !isSubmitting && "bg-darkPrimary"
        } rounded-full mx-auto mt-[1.5rem] md:mt-[3.75rem] font-bold`}
      >
        {isSubmitting ? (
          <Loader />
        ) : (
          <>
            {sectionIndex > 0 && (
              <button
                type="button"
                onClick={saveDataAndGoBack}
                className="bg-darkPrimary text-base 2xl:text-[1.68rem] py-2 pl-10 pr-9 sm:pl-20 sm:pr-14 text-white rounded-full"
              >
                PREVIOUS
              </button>
            )}
            <button
              type="submit"
              className={`bg-secondary text-base 2xl:text-[1.68rem] text-darkPrimary py-2 ${
                currentlyOnFirstSection ? "px-24" : "px-12 border-l-2"
              } sm:px-24 rounded-full border-white ${
                (!isValid || Object.keys(errors).length > 0) &&
                "bg-[#f9e491] text-[#8c78ad]"
              }`}
              onClick={handleSubmit(saveData)}
            >
              CONTINUE
            </button>
          </>
        )}
      </div>
    </>
  );
};

import UTI from "../assets/images/treatmentsOffered/UTI-Treatment.webp";
import YeastInfection from "../assets/images/treatmentsOffered/Yeast-Infection.webp";
import BirthControlImage from "../assets/images/treatmentsOffered/Birth-Control.webp";
import BacterialVaginosis from "../assets/images/treatmentsOffered/Bacterial-Vaginosis.webp";
import ColdSoreTreatment from "../assets/images/treatmentsOffered/ColdSore.webp";
import Lubricant from "../assets/images/treatmentsOffered/Lubricants.webp";
import GenitalHerpes from "../assets/images/treatmentsOffered/Genital-Herpes.webp";
import PreIntimacyWellness from "../assets/images/treatmentsOffered/PreIntemacyWellnessImage.webp";
import ErectileDysfunction from "../assets/images/treatmentsOffered/Erectile.webp";
import SexualWellness from "../assets/images/treatmentsOffered/SexualWellness.webp";

import UTITreatmentHeader from "../assets/images/TreatmentLandingPage/UTITreatmentHeader.webp";
import UTITreatmentFooter from "../assets/images/TreatmentLandingPage/UTITreatmentFooter.webp";
import BVTreatmentHeader from "../assets/images/TreatmentLandingPage/BVTreatmentHeader.webp";
import BVTreatmentFooter from "../assets/images/TreatmentLandingPage/BVTreatmentFooter.webp";
import CSTreatmentHeader from "../assets/images/TreatmentLandingPage/CSTreatmentHeader.webp";
import CSTreatmentFooter from "../assets/images/TreatmentLandingPage/CSTreatmentFooter.webp";
import EDTreatmentHeader from "../assets/images/TreatmentLandingPage/EDTreatmentHeader.webp";
import EDTreatmentFooter from "../assets/images/TreatmentLandingPage/EDTreatmentFooter.webp";
import GHTreatmentHeader from "../assets/images/TreatmentLandingPage/GHTreatmentHeader.webp";
import GHTreatmentFooter from "../assets/images/TreatmentLandingPage/GHTreatmentFooter.webp";
import YITreatmentHeader from "../assets/images/TreatmentLandingPage/YITreatmentHeader.webp";
import YITreatmentFooter from "../assets/images/TreatmentLandingPage/YITreatmentFooter.webp";

import UTIEclipse from "../assets/icons/treatmentUTIEclipse.svg";
import EDEclipse from "../assets/icons/treatmentEDEclipse.svg";
import CSEclipse from "../assets/icons/treatmentCSEclipse.svg";
import GHEclipse from "../assets/icons/treatmentGHEclipse.svg";
import YIEclipse from "../assets/icons/treatmentYIEclipse.svg";

import { colors } from "../theme";
import { PriorDiagnosisSvg } from "../components/svgs/PriorDiagnosisSvg";
import { PhotoIdSvg } from "../components/svgs/PhotoIdSvg";
import { LaptopSvg } from "../components/svgs/LaptopSvg";

export const getWhatsNeededLayout = {
  card: "cards",
  list: "list",
};

/**
 *
 * Herpes
 *
 */
const genitalHerpesContent = {
  hero: {
    name: "Genital Herpes",
    title: "Genital Herpes Treatment",
    text: "Prevent genital herpes outbreaks with antiviral medication. Same Day Prescriptions or receive genital herpes treatment discreetly to your home",
    image: GHTreatmentHeader,
  },
  infoBanner: {
    paraOne:
      "Get personalized treatment for genital herpes from a licensed clinician by filling out an intake form. Your clinician will carefully evaluate your information and provide a personalized treatment plan that may involve a prescription for genital herpes medication. No appointments needed, but a prior diagnosis is necessary.",
    checkMarkColor: "#CA9351",
    checkList: [
      "You've been diagnosed with genital herpes",
      "You're having an outbreak or want to prevent future outbreaks",
      "You don't have kidney problems like abnormal kidney function",
      "You don't have a seizure disorder",
      "You're not pregnant",
    ],
  },
  backgroundColor: "bg-[#ffe7e5]",
  getWhatsNeeded: {
    layout: getWhatsNeededLayout.card,
    cardList: (iconStyle) => {
      return [
        {
          icon: (
            <PriorDiagnosisSvg
              fillColor={colors.darkPrimary}
              style={iconStyle}
            />
          ),
          title: "A Prior Diagnosis",
          text: "A prior diagnosis of genital herpes by a licensed healthcare provider",
        },
        {
          icon: <PhotoIdSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Patient Identification",
          text: "A photo ID, like a driver's license or passport, if we need additional identity verification",
        },
        {
          icon: <LaptopSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Length of Time",
          text: "5 to 10 minutes to answer questions about your symptoms and health history",
        },
      ];
    },
  },
  footerHero: {
    question: "What are genital herpes symptoms?",
    answer: (
      <>
        Herpes is a common infection that can affect dating and relationships,
        and unfortunately, many people may not be aware that they have it. In
        fact, it's estimated that 1 in 8 adults in the US have the HSV-2 virus,
        which is the primary cause of genital herpes (although it can also be
        caused by HSV-1). While some people may mistake outbreaks as genital
        pimples or fever blisters, they are typically painful genital ulcers and
        are often a sign of genital herpes. Once someone is infected with HSV-2,
        they may experience genital outbreaks for the rest of their life
      </>
    ),
    image: GHTreatmentFooter,
  },
  common: {
    eclipse: GHEclipse,
  },
};

/**
 *
 * ED CONTENT
 *
 */

const erectileDysfunctionContent = {
  hero: {
    name: "ED",
    title: "Erectile Dysfunction Treatment",
    text: "Same Day Prescriptions or receive an ED medication discreetly to your home",
    image: EDTreatmentHeader,
  },
  infoBanner: {
    paraOne:
      "Skip the appointment and get ED treatment from a licensed clinician by simply filling out an intake form. Your clinician will carefully evaluate your information and provide a personalized treatment plan, which may include a prescription for ED medication. No need to wait for an appointment!",
    checkMarkColor: "#A499F7",
    checkList: [
      "You struggle to maintain an erection",
      "You don't have uncontrolled high blood pressure or a history of heart disease",
    ],
  },
  backgroundColor: "bg-[#ece9fe]",
  getWhatsNeeded: {
    layout: getWhatsNeededLayout.card,
    cardList: (iconStyle) => {
      return [
        {
          icon: (
            <PriorDiagnosisSvg
              fillColor={colors.darkPrimary}
              style={iconStyle}
            />
          ),
          title: "Blood Pressure Reading",
          text: "A blood pressure reading from the past 12 months",
        },
        {
          icon: (
            <PriorDiagnosisSvg
              fillColor={colors.darkPrimary}
              style={iconStyle}
            />
          ),
          title: "Physical Exam",
          text: "A physical exam within the past 5 years",
        },
        {
          icon: <PhotoIdSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Patient Identification",
          text: "A photo ID, like a driver's license or passport, if we need additional identity verification",
        },
        {
          icon: <LaptopSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Length of Time",
          text: "5 to 10 minutes to answer questions about your symptoms and health history",
        },
      ];
    },
  },
  footerHero: {
    question: "What are Erectile Disfunction Symptoms?",
    answer: (
      <>
        Erectile dysfunction (ED) is a condition that affects a man's ability to
        achieve or maintain an erection firm enough for sexual activity.
        <br />
        <span className="flex my-4">The symptoms of ED can include:</span>
        <ul>
          <li>• Difficulty getting an erection</li>
          <li>• Difficulty maintaining an erection during sexual activity</li>
          <li>• Reduced sexual desire or libido</li>
          <li>• Premature ejaculation or delayed ejaculation</li>
          <li>• Inability to achieve an erection at all</li>
          <li>• Cloudy or foul-smelling urine</li>
          <li>• Pain in the lower abdomen or back</li>
          <li>• Nausea and vomiting</li>
          <li>• Fatigue and general malaise</li>
        </ul>
      </>
    ),
    image: EDTreatmentFooter,
  },
  common: {
    eclipse: EDEclipse,
  },
};

/**
 *
 * UTI CONTENT
 *
 */

const utiContent = {
  hero: {
    name: "UTI",
    title: "Urinary Tract Infection Treatment",
    text: "Same Day Prescriptions or receive UTI medication discreetly to your home",
    image: UTITreatmentHeader,
  },
  infoBanner: {
    paraOne:
      "UTI symptoms can be uncomfortable and inconvenient, but getting treatment doesn't have to be. Fill out an intake form, and a licensed clinician will evaluate your information and provide a personalized treatment plan, which may include a prescription for UTI medication. No need for appointments!",
    checkMarkColor: "#F5D447",
    checkList: [
      "Your sex assigned at birth was female",
      "You haven't already been treated with anitbiotic for your current symptoms",
      "This is your only UTI in past three months",
      "You haven't had more than 3 UTIs in the past 12 months",
      "You're not pregnant",
    ],
  },
  backgroundColor: "bg-[#fff7d6]",
  getWhatsNeeded: {
    layout: getWhatsNeededLayout.list,
    cardList: (iconStyle) => {
      return [
        {
          icon: <PhotoIdSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Patient Identification",
          text: "A photo ID, like a driver's license or passport, if we need additional identity verification",
        },
        {
          icon: <LaptopSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Length of Time",
          text: "5 to 10 minutes to answer questions about your symptoms and health history",
        },
      ];
    },
  },
  footerHero: {
    question: "What are Urinary Tract Infection (UTI) Symptoms?",
    answer: (
      <>
        Urinary tract infections (UTIs) are a common bacterial infection that
        affects any part of the urinary tract, including the kidneys, bladder,
        ureters, and urethra. The symptoms of a UTI can vary depending on the
        location and severity of the infection, but may include:
        <ul className="mt-4">
          <li>• Pain or burning during urination</li>
          <li>• Frequent urge to urinate</li>
          <li>• Passing small amounts of urine</li>
        </ul>
      </>
    ),
    image: UTITreatmentFooter,
  },
  common: {
    eclipse: UTIEclipse,
  },
};

/**
 *
 * BIRTH CONTROL CONTENT
 *
 */
const birthControlContent = {
  hero: {
    name: "Birth Control",
    title: "Order Birth Control Online",
    text: "Same Day Prescriptions or receive birth control discreetly to your home. Prevent disease with antiviral medication",
  },
  infoBanner: {
    paraOne:
      "Take control of your reproductive health – request a birth control prescription from a licensed clinician by filling out an intake form. No need for appointments!",
    checkMarkColor: "#F5D447",
    checkList: [
      "You don't have any blood-clotting disorders like deep vein thrombosis (DVT)",
      "You don't have cancer of the breast, cervix, or uterus",
      "Your blood pressure is lower then 160/90",
    ],
  },
  backgroundColor: "bg-[#ece9fe]",
  getWhatsNeeded: {
    layout: getWhatsNeededLayout.card,
    cardList: (iconStyle) => {
      return [
        {
          icon: <PhotoIdSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Blood Pressure Reading",
          text: "A blood pressure reading from the past 12 months",
        },
        {
          icon: <PhotoIdSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Height and Weight",
          text: "Your height and weight, if you're interested in birth control patches",
        },
        {
          icon: <PhotoIdSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Patient Identification",
          text: "A photo ID, like a driver's license or passport, if we need additional identity verification",
        },
        {
          icon: <LaptopSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Length of Time",
          text: "5 to 10 minutes to answer questions about your symptoms and health history",
        },
      ];
    },
  },
};

/**
 *
 * COLD SORE CONTENT
 *
 */

const coldSoreContent = {
  hero: {
    name: "Cold Sore",
    title: "Cold Sore Treatment",
    text: "Stop oral herpes outbreaks with antiviral medication. Same Day Prescriptions or receive oral herpes treatment discreetly to your home",
    image: CSTreatmentHeader,
  },
  infoBanner: {
    paraOne:
      "Obtain treatment for cold sores (oral herpes) from a licensed clinician by filling out an intake form. Your clinician will evaluate your information and provide a personalized treatment plan for cold sores, which may involve a prescription for medication. No appointments necessary!",
    checkMarkColor: "#CA9351",
    checkList: [
      "You've been diagnosed with oral herpes (cold sores)",
      "You have sores on or around your mouth or you want to prevent future outbreaks",
      "You have normal kidney function",
      "You don't have a seizure disorder",
      "You're not pregnant",
    ],
  },
  backgroundColor: "bg-[#ffebd3]",
  getWhatsNeeded: {
    layout: getWhatsNeededLayout.card,
    cardList: (iconStyle) => {
      return [
        {
          icon: <PhotoIdSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "A Prior Diagnosis",
          text: "A prior diagnosis of oral herpes by a licensed healthcare provider",
        },
        {
          icon: <PhotoIdSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "2 Photos",
          text: "2 photos: a close-up of the affected area and one that also shows the sorrounding skin",
        },
        {
          icon: <PhotoIdSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Patient Identification",
          text: "A photo ID, like a driver's license or passport, if we need additional identity verification",
        },
        {
          icon: <LaptopSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Length of Time",
          text: "5 to 10 minutes to answer questions about your symptoms and health history",
        },
      ];
    },
  },
  footerHero: {
    question: "What are Cold Sore symptoms?",
    answer: (
      <>
        Cold sores, also referred to as fever blisters, lip ulcers, or canker
        sores, are painful blisters that develop around the lips or inside the
        mouth. They are caused by the Herpes Simplex Virus (HSV- 1) and can
        cause both physical discomfort and emotional distress. Even a single
        cold sore is considered an outbreak, although outbreaks typically
        include multiple sores. The severity of an outbreak can depend on
        various factors, such as the frequency of previous outbreaks or how
        quickly antiviral medication is taken. If you're experiencing cold sore
        symptoms, seeking treatment from a licensed clinician can help alleviate
        your symptoms and prevent further outbreaks.
      </>
    ),
    image: CSTreatmentFooter,
  },
  common: {
    eclipse: CSEclipse,
  },
};

/**
 *
 * YEAST INFECTION CONTENT
 *
 */

const yeastInfectionContent = {
  hero: {
    name: "Yeast Infection",
    title: "Yeast Infection Treatment",
    text: "Order same day yeast infection medicine. Same Day Prescriptions or receive yeast infection treatment discreetly to your home",
    image: YITreatmentHeader,
  },
  infoBanner: {
    paraOne:
      "Obtain treatment for vaginal yeast infections from a licensed clinician by filling out an intake form. Your clinician will evaluate your information and provide a personalized candidiasis treatment plan that may include a prescription for yeast infection medication. No appointments are necessary for this convenient and efficient process.",
    checkMarkColor: "#51AECA",
    checkList: [
      "You've sex assigned at birth was female and you're having vaginal yeist infections symptons",
      "You're not experiencing nausea, vomiting, back pain, or fever",
      "You've had fewer than 4 yeast infections in the past 12 months",
      "You're not pregnant",
    ],
  },
  backgroundColor: "bg-[#cdf3ff]",
  getWhatsNeeded: {
    layout: getWhatsNeededLayout.list,
    cardList: (iconStyle) => {
      return [
        {
          icon: <PhotoIdSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Patient Identification",
          text: "A photo ID, like a driver's license or passport, if we need additional identity verification",
        },
        {
          icon: <LaptopSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Length of Time",
          text: "5 to 10 minutes to answer questions about your symptoms and health history",
        },
      ];
    },
  },
  footerHero: {
    question: "What are the symptoms of a Yeast Infection?",
    answer: (
      <>
        The symptoms of a yeast infection in women can vary from person to
        person but may include:
        <ul className="mt-4">
          <li>• Itching and irritation in the vaginal area</li>
          <li>• Burning sensation during urination or intercourse</li>
          <li>
            • Thick, white, odorless vaginal discharge that may resemble cottage
            cheese
          </li>
          <li>• Redness and swelling of the vulva</li>
          <li>• Soreness or rash on the vagina or surrounding areas</li>
        </ul>
      </>
    ),
    image: YITreatmentFooter,
  },
  common: {
    eclipse: YIEclipse,
  },
};

/**
 *
 * Bacterial Vaginosis CONTENT
 *
 */

const bacterialVaginosisContent = {
  hero: {
    name: "BV",
    title: "Bacterial Vaginosis Treatment",
    text: "Order same day yeast infection medicine. Same Day Prescriptions or receive yeast infection treatment discreetly to your home",
    image: BVTreatmentHeader,
  },
  infoBanner: {
    paraOne:
      "Obtain treatment for bacterial vaginosis from a licensed clinician by filling out an intake form. Your clinician will evaluate your information and provide a personalized candidiasis treatment plan that may include a prescription for yeast infection medication. No appointments are necessary for this convenient and efficient process.",
    checkMarkColor: "#4845B4",
    checkList: [
      "You've sex assigned at birth was female and you're having vaginal yeist infections symptons",
      "You're not experiencing nausea, vomiting, back pain, or fever",
      "You've had fewer than 4 yeast infections in the past 12 months",
      "You're not pregnant",
    ],
  },
  backgroundColor: "bg-[#ccdcfe]",
  getWhatsNeeded: {
    layout: getWhatsNeededLayout.list,
    cardList: (iconStyle) => {
      return [
        {
          icon: <PhotoIdSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Patient Identification",
          text: "A photo ID, like a driver's license or passport, if we need additional identity verification",
        },
        {
          icon: <LaptopSvg fillColor={colors.darkPrimary} style={iconStyle} />,
          title: "Length of Time",
          text: "5 to 10 minutes to answer questions about your symptoms and health history",
        },
      ];
    },
    footerHero: {
      question: "What are the symptoms of Bacterial Vaginosis?",
      answer: (
        <>
          Bacterial Vaginosis is a condition characterized by uncomfortable
          inflammation of the vagina, resulting from an overgrowth of bacteria
          that are typically found naturally in the vaginal environment. This
          overgrowth often occurs due to a disruption of the natural balance of
          vaginal flora. Symptoms may include unusual vaginal discharge or odor,
          but many people do not seek treatment for Bacterial Vaginosis due to
          embarrassment or lack of knowledge about the condition. However, it's
          important to seek treatment for BV, as untreated infections can cause
          inflammation that increases the risk of contracting sexually
          transmitted infections, such as HIV, Chlamydia, or Gonorrhea.
          <br />
          While BV can affect anyone, individuals in their reproductive years
          are most commonly affected. If you experience frequent infections,
          using Boric Acid or Reproductive Probiotics in addition to
          prescription treatment may significantly reduce chronic BV symptoms.
          Certain activities, such as frequent douching, can also increase the
          risk of BV recurrence. It's important to speak with a healthcare
          provider if you suspect you have BV to receive proper diagnosis and
          treatment.
        </>
      ),
      image: BVTreatmentFooter,
    },
  },
  common: {
    eclipse: YIEclipse,
  },
};

export const treatments = [
  {
    image: UTI,
    name: "UTI Treatment",
    href: "/urinary_tract_infection/uti_preliminary_survey",
    campaignPageHref: "/urinary_tract_infection",
    content: utiContent,
    searchable_tag: "uti_preliminary_survey",
    description:
      "A urinary tract infection is a bacterial infection treated with antibiotics.",
    cardColor: "#FCE892",
  },
  {
    image: BirthControlImage,
    name: "Birth Control",
    href: "/coming_soon",
    campaignPageHref: "/coming_soon",
    content: birthControlContent,
    description:
      "Birth control prevents pregnancy using methods like pills, patches, and IUDs.",
    cardColor: "#ECE9FF",
  },
  {
    image: GenitalHerpes,
    name: "Genital Herpes",
    href: "/genital_herpes/herpes_preliminary_survey",
    campaignPageHref: "/genital_herpes",
    searchable_tag: "herpes_preliminary_survey",
    content: genitalHerpesContent,
    description:
      "Genital herpes is a viral infection managed with antiviral medications.",
    cardColor: "#FFD075",
  },
  {
    image: ErectileDysfunction,
    name: "Erectile Dysfunction",
    href: "/erectile_dysfunction/ed_preliminary_survey",
    campaignPageHref: "/erectile_dysfunction",
    content: erectileDysfunctionContent,
    searchable_tag: "ed_preliminary_survey",
    description:
      "Erectile dysfunction is the inability to maintain an erection, treated with medications",
    cardColor: "#BDF3FF",
  },
  {
    image: YeastInfection,
    name: "Yeast Infections",
    href: "/yeast_infection/yeast_preliminary_survey",
    campaignPageHref: "/yeast_infection",
    content: yeastInfectionContent,
    searchable_tag: "yeast_preliminary_survey",
    description:
      "Yeast infections are fungal infections treated with antifungal medications.",
    cardColor: "#B6ADFF",
  },
  {
    image: BacterialVaginosis,
    name: "Bacterial Vaginosis",
    href: "/bacterial_vaginosis/bv_preliminary_survey",
    campaignPageHref: "/bacterial_vaginosis",
    content: bacterialVaginosisContent,
    searchable_tag: "bv_preliminary_survey",
    description:
      "Bacterial vaginosis is an imbalance of vaginal bacteria, treated with antibiotics.",
    cardColor: "#F9F9F9",
  },
  {
    image: ColdSoreTreatment,
    name: "Cold Sore Treatment",
    href: "/genital_herpes/herpes_preliminary_survey",
    campaignPageHref: "/cold_sore",
    content: coldSoreContent,
    description:
      "Cold sores are painful blisters caused by the herpes virus, treated with antivirals.",
    cardColor: "#FCE892",
  },
  {
    image: Lubricant,
    name: "Lubricant",
    href: "/coming_soon",
    campaignPageHref: "/coming_soon",
    description:
      "Lubricant is a substance used to reduce friction and enhance comfort during sexual activity.",
    cardColor: "#ECE9FF",
  },
  {
    image: PreIntimacyWellness,
    name: "Pre Intimacy Wellness",
    href: "/coming_soon",
    campaignPageHref: "/coming_soon",
    description:
      "Pre-Intimacy Wellness involves preparing the body and mind for sexual activity to enhance comfort and enjoyment.",
    cardColor: "#BDF3FF",
  },
  {
    image: SexualWellness,
    name: "Sexual Wellness Products",
    href: "/coming_soon",
    campaignPageHref: "/coming_soon",
    description:
      "Sexual wellness products are items designed to improve sexual health, comfort, and pleasure.",
    cardColor: "#F9F9F9",
  },
];
